import { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { useAccount, useNetwork, useSigner } from 'wagmi';

import { useAppDispatch } from 'store/hooks';
import { setChainId } from 'store/wallet';

export default function useWeb3Info() {
  const { account, active, chainId, library } = useWeb3React<Web3Provider>();
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const { data: signer } = useSigner();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (chainId || chain?.id) dispatch(setChainId(chainId || chain?.id));
  }, [chainId, chain]);

  return {
    account: account || address,
    active: active || isConnected,
    chainId: chainId || chain?.id,
    library: library || (signer?.provider as Web3Provider),
  };
}
