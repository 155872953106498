import { keyframes, css } from '@emotion/react';
import { CSSProperties } from 'react';

export const shakeAnimation = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }`;

type Flex = {
  display?: 'flex' | 'inline-flex';
  direction?: CSSProperties['flexDirection'];
  alignItems?: CSSProperties['alignItems'];
  justifyContents?: CSSProperties['justifyContent'];
};

/**
 * @description flex 스타일 적용을 위한 mixin 함수
 */
export const flex = ({
  display = 'flex',
  direction = 'row',
  alignItems = 'flex-start',
  justifyContents = 'flex-start',
}: Flex) => css`
  display: ${display};
  flex-direction: ${direction};
  align-items: ${alignItems};
  justify-content: ${justifyContents};
`;
