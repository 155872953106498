/* eslint-disable no-nested-ternary */
import { blockExplorerUrl } from 'lib/environment';
import {
  chainIdToMainNetworkName,
  chainIdToNetworkName,
  getChainHexCode,
  rpcUrls,
} from 'lib/network';
import { ProviderRpcError } from 'types/error';
import { ChainId } from 'types/network';

const switchNetwork = async (target: ChainId, callback?: () => void) => {
  const provider = localStorage.getItem('PDMP_WALLET_PROVIDER');

  if (target === 'devnet' || target === 'mainnet-beta') return;

  if (provider === 'kaikas' && (target === 1001 || target === 8217)) {
    const chainHexCode = getChainHexCode(target);
    try {
      await window.klaytn?.request({
        method: 'wallet_switchKlaytnChain',
        params: [{ chainId: chainHexCode }],
      });
      localStorage.setItem('PDMP_NETWORK', chainIdToMainNetworkName[target]);
      if (callback) callback();
      return;
    } catch (err) {
      const switchError = err as ProviderRpcError;

      if (switchError?.code === 4902) {
        await window.klaytn?.request({
          method: 'wallet_addKlaytnChain',
          params: [
            {
              chainId: chainHexCode,
              chainName: `${chainIdToNetworkName[target]} Network`,
              rpcUrls: [rpcUrls[target]],
              nativeCurrency: {
                name: 'KLAY',
                symbol: 'KLAY',
                decimals: 18,
              },
              blockExplorerUrls: [
                blockExplorerUrl[
                  (process.env.NEXT_PUBLIC_ENV as
                    | 'development'
                    | 'production') || ('production' as const)
                ][chainIdToMainNetworkName[target]],
              ],
            },
          ],
        });
      }
      return;
    }
  }

  const chainHexCode = getChainHexCode(target);
  try {
    if (provider === 'kaikas') return;

    await window.ethereum?.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainHexCode }],
    });
    localStorage.setItem('PDMP_NETWORK', chainIdToMainNetworkName[target]);
    if (callback) {
      callback();
    }
    return;
  } catch (error) {
    const switchError = error as ProviderRpcError;
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError?.code === 4902) {
      await window.ethereum?.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: chainHexCode,
            chainName: `${chainIdToNetworkName[target]} Network`,
            rpcUrls: [rpcUrls[target]],
            nativeCurrency: {
              name:
                chainIdToMainNetworkName[target] === 'polygon'
                  ? 'MATIC'
                  : chainIdToMainNetworkName[target] === 'klaytn'
                  ? 'KLAY'
                  : 'ETH',
              symbol:
                chainIdToMainNetworkName[target] === 'polygon'
                  ? 'MATIC'
                  : chainIdToMainNetworkName[target] === 'klaytn'
                  ? 'KLAY'
                  : 'ETH',
              decimals: 18,
            },
            blockExplorerUrls: [
              blockExplorerUrl[
                (process.env.NEXT_PUBLIC_ENV as 'development' | 'production') ||
                  ('production' as const)
              ][chainIdToMainNetworkName[target]],
            ],
          },
        ],
      });
    }

    throw new Error(switchError.message);
  }
};

export default switchNetwork;
