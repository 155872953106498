import { useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import { Web3Provider } from '@ethersproject/providers';
import { Modal, spacing, Typography, Button } from '@playdapp/ui';
import { useWeb3React } from '@web3-react/core';
import { useDisconnect } from 'wagmi';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setConnector } from 'store/marketplace';
import useSolana from 'hooks/useSolana';
import useWeb3Info from 'hooks/useWeb3Info';
import useToastMessage from 'hooks/useToastMessage';
import { setPageNetwork } from 'store/page';
import {
  resetUserInfo,
  fetchUserInfo,
  fetchUserProfile,
  readyToUpdate,
} from 'store/user';
import {
  setChainId,
  resetWalletInfo,
  getBalances,
  setWalletInfo,
} from 'store/wallet';
import { removeLoginInfo } from 'lib/auth';
import {
  chainIdToMainNetworkName,
  networkNameToChainId,
  chainIdToNetworkName,
} from 'lib/network';
import { convertPascalCase } from 'lib/util';
import switchNetwork from 'lib/web3/switchNetwork';
import { ChainId } from 'types/network';

type Props = {
  isOpen: boolean;
  shouldLogoutOnClose?: boolean;
  handleOpen: (openState: boolean) => void;
  handleSignInModalClose?: () => void;
};

const NetworkAlertBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-top: ${spacing.s};
    text-align: center;
    padding: 0 ${spacing.s};
  }

  button {
    margin-top: ${spacing.xxl};
    font-weight: 600;
  }
`;

const NetworkAlert = ({
  isOpen,
  shouldLogoutOnClose = true,
  handleOpen,
  handleSignInModalClose,
}: Props) => {
  const { deactivate } = useWeb3React<Web3Provider>();
  const { account, library } = useWeb3Info();
  const { disconnect } = useSolana();
  const { disconnect: wagmiDisconnect } = useDisconnect();
  const dispatch = useAppDispatch();
  const { provider, activeChainId } = useAppSelector(({ wallet }) => wallet);
  const { netWork: pageNetwork } = useAppSelector(({ page }) => page);
  const { handleUpdateToast, handleCloseToast } = useToastMessage({
    position: 'top',
    duration: 5000,
  });

  const switchChainId = useMemo(
    () =>
      pageNetwork
        ? networkNameToChainId[pageNetwork]
        : (activeChainId as ChainId),
    [pageNetwork, activeChainId],
  );

  const isSwitchable = useMemo(
    () =>
      (pageNetwork !== 'solana' &&
        provider === 'metamask' &&
        window.ethereum &&
        window.ethereum.isMetaMask) ||
      (provider === 'kaikas' &&
        window.klaytn &&
        window.klaytn.isKaikas &&
        (switchChainId === 1001 || switchChainId === 8217)) ||
      provider === 'walletlink',
    [provider, pageNetwork, switchChainId],
  );

  const handleSwitchNetworkCallback = () => {
    dispatch(setChainId(switchChainId));
  };

  const handleSwitchNetwork = async () => {
    try {
      if (switchChainId) {
        await switchNetwork(switchChainId, handleSwitchNetworkCallback);

        if (account && library && switchChainId) {
          dispatch(
            setConnector({
              network: chainIdToMainNetworkName[+switchChainId],
              library,
            }),
          );
          dispatch(fetchUserInfo({ account }));
          dispatch(fetchUserProfile({ account }));
          dispatch(readyToUpdate());
          dispatch(
            setWalletInfo({
              account,
              provider,
              activeChainId: switchChainId,
              library,
              chainId: switchChainId,
            }),
          );
          dispatch(getBalances({ account, library, chainId: +switchChainId }));
          if (handleSignInModalClose) handleSignInModalClose();
        }
        handleOpen(false);
      }
    } catch (e) {
      handleUpdateToast('Switch fail', 'error', true);
    }
  };

  const handleLogout = () => {
    removeLoginInfo();
    dispatch(resetWalletInfo());
    dispatch(resetUserInfo());
    dispatch(setPageNetwork(null));
    deactivate();
    disconnect();
    wagmiDisconnect();
    handleOpen(false);
  };

  useEffect(
    () => () => {
      if (isOpen) {
        handleCloseToast();
      }
    },
    [isOpen],
  );

  return (
    <Modal
      isOpen={isOpen}
      handleOpen={handleSignInModalClose ? handleLogout : handleOpen}
      handleAfterClose={shouldLogoutOnClose ? handleLogout : undefined}
      width={{ lg: '498px', xl: '498px' }}
    >
      <NetworkAlertBlock>
        <Typography type="h3" component="h3">
          {isSwitchable ? 'Switch Network' : 'Wrong network'}
        </Typography>
        <Typography color="gray900" component="p">
          {isSwitchable
            ? `Your wallet is connected to the wrong network. To use ${convertPascalCase(
                chainIdToMainNetworkName[switchChainId],
              )} Marketplace, please switch the network.`
            : `Your wallet is connected to the wrong network. To use ${convertPascalCase(
                chainIdToMainNetworkName[switchChainId],
              )} Marketplace, Please sign in again.`}
        </Typography>
        <Button
          onClick={isSwitchable ? handleSwitchNetwork : handleLogout}
          size="lg"
        >
          {isSwitchable && switchChainId
            ? `Switch ${chainIdToNetworkName[switchChainId]}`
            : 'Sign in'}
        </Button>
      </NetworkAlertBlock>
    </Modal>
  );
};

export default NetworkAlert;
