import SwiperProps from 'types/swiper';

const sliderOptions: SwiperProps = {
  slidesPerView: 'auto',
  effect: 'slide',
  speed: 700,
  loop: true,
  draggable: true,
  fadeEffect: {
    crossFade: false,
  },
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  pagination: false,
  navigation: false,
};

export default sliderOptions;
