export const blockExplorerUrl = {
  production: {
    ethereum: 'https://etherscan.io',
    polygon: 'https://polygonscan.com',
    solana: 'https://explorer.solana.com',
    klaytn: 'https://scope.klaytn.com',
  },
  development: {
    ethereum: 'https://sepolia.etherscan.io',
    polygon: 'https://www.oklink.com/amoy',
    solana: 'https://explorer.solana.com',
    klaytn: 'https://baobab.scope.klaytn.com',
  },
};

export default null;
