import styled from '@emotion/styled';
import {
  Button,
  breakpoints,
  fontStyle,
  Modal,
  spacing,
  palette,
} from '@playdapp/ui';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMedia } from 'react-use';

import sliderOptions from 'data/sliderOptions';
import { getBreakpointQuery } from 'lib/util';

import Image from '@/components/Image';

import 'swiper/css';
import 'swiper/css/pagination';

SwiperCore.use([Autoplay, Pagination]);

type Props = {
  isOpen: boolean;
  handleOpen: (isOpen: boolean) => void;
};

const ModalContents = styled.div`
  @media (min-height: 480px) and (max-width: 480px) {
    max-height: 80vh;
    overflow-y: scroll;
  }

  @media (max-height: 480px) {
    max-height: 60vh;
    overflow-y: scroll;
  }
`;

const TitleSection = styled.section`
  text-align: center;
`;

const Title = styled.span`
  ${fontStyle('h3')};

  ${breakpoints.down('sm')} {
    ${fontStyle('h4')};
  }
`;

const SwiperBlock = styled.div`
  position: relative;
  height: 520px;
  margin-top: ${spacing.s};

  .swiper {
    height: 100%;
  }

  .swiper-pagination {
    position: absolute;
    bottom: -6px;
  }

  .swiper-pagination-bullet {
    background-color: ${palette.gray800};
  }

  ${breakpoints.down('sm')} {
    height: 100%;
  }
`;

const SwiperImageBlock = styled.figure`
  display: flex;
  justify-content: center;
`;

const SwiperTitleBlock = styled.div`
  text-align: center;
  margin-top: ${spacing.s};
  margin-bottom: ${spacing.m};

  span {
    ${fontStyle('b1')};
    color: ${palette.black};
  }
`;

const SwiperDescBlock = styled.div`
  text-align: center;

  span {
    ${fontStyle('p4')};
    color: ${palette.gray900};
  }

  ${breakpoints.down('sm')} {
    padding-bottom: ${spacing.xl};
  }
`;

const ButtonSection = styled.section`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  margin-top: ${spacing.xl};

  button,
  a {
    width: 50%;
    height: auto;
    min-height: 2.5rem;
    border-radius: 8px;
  }

  a > button {
    width: 100%;
  }

  ${breakpoints.down('sm')} {
    flex-direction: column-reverse;
    justify-content: center;

    button,
    a {
      width: 100%;
    }
  }
`;

const SLIDER_LIST = [
  {
    id: 1,
    img: '/connect/how-to-sign-in01.png',
    title: 'Tip!',
    desc: "Don't panic! Let's look into the network first. The network is the place where the blockchain ecosystem is formed and elaborated.",
  },
  {
    id: 2,
    img: '/connect/how-to-sign-in02.png',
    title: 'Tip!',
    desc: 'Consider the wallet as your persona on the blockchain network. For instance, it can allow you to gain access to the blockchain-based platform on your behalf, and use it to keep and trade token assets with another party.',
  },
];

const HowToSignInModal = ({ isOpen, handleOpen }: Props) => {
  const isSmDownScreen = useMedia(getBreakpointQuery(breakpoints.down('sm')));

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      width={{ xl: '498px', lg: '498px', md: '498px' }}
      handleOpen={handleOpen}
      hideCloseButton
    >
      <ModalContents>
        <TitleSection>
          <Title>How to sign in?</Title>
        </TitleSection>
        <SwiperBlock>
          <Swiper
            {...sliderOptions}
            pagination={{
              clickable: true,
            }}
          >
            {SLIDER_LIST.map((item) => (
              <SwiperSlide key={`how-to-sign-in-slide-${item.id}`}>
                <SwiperImageBlock>
                  <Image
                    prefix="s3"
                    src={item.img}
                    width={isSmDownScreen ? '160px' : '312px'}
                    height={isSmDownScreen ? '160px' : '312px'}
                    layout="fixed"
                    priority
                  />
                </SwiperImageBlock>
                <SwiperTitleBlock>
                  <span>{item.title}</span>
                </SwiperTitleBlock>
                <SwiperDescBlock>
                  <span>{item.desc}</span>
                </SwiperDescBlock>
              </SwiperSlide>
            ))}
          </Swiper>
        </SwiperBlock>
        <ButtonSection>
          <Button variant="outline" onClick={() => handleOpen(false)}>
            Close
          </Button>
          <a href="/faq/wallet" target="_blank">
            <Button>More information</Button>
          </a>
        </ButtonSection>
      </ModalContents>
    </Modal>
  );
};

export default HowToSignInModal;
